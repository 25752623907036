// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
//import "@hotwired/turbo-rails"
//import "controllers"
import Rails from '@rails/ujs';
import * as bootstrap from "bootstrap";
import * as WebAuthnJSON from "@github/webauthn-json";

Rails.start();

global.csrfToken = document.getElementsByName('csrf-token')[0].content;

global.webauthn_callback = function(url, body) {
    fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: 'same-origin'
    }).then(function(response) {
        if(response.ok) {
            console.log("Request was successful");
            window.location.replace("/");
        } else if (response.status < 500) {
            response.text().then(alert);
        } else {
            alert("Unexpected error");
        }
    });
}

global.webauthn_create = function(callbackUrl, credentialOptions) {
    WebAuthnJSON.create({ "publicKey": credentialOptions }).then(function(credential) {
        webauthn_callback(callbackUrl, credential);
    }).catch(function(error){
        alert(error);
    });
}

global.webauthn_get = function(callbackUrl, credentialOptions){
    WebAuthnJSON.get({ "publicKey": credentialOptions}).then(function(credential){
        webauthn_callback(callbackUrl, credential);
    }).catch(function(error){
        alert(error);
    });
}
